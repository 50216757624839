import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 入园企业 - 分页查询
 * @param params
 */
export function getParkEnterpriseList(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/enterprise/getEnterpriseByParam',
    method: 'get',
    params,
  });
}

/**
 * 入园企业 - 新增
 * @param params
 */
export function addParkEnterprise(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/enterprise/addEnterprise',
    method: 'post',
    data: params,
  });
}

/**
 * 入园企业 - 删除
 * @param params
 */
export function deleteParkEnterprise(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/enterprise/deleteEnterprise',
    method: 'post',
    data: params,
  });
}

/**
 * 入园企业 - 查询详情
 * @param params
 */
export function getParkEnterpriseDetail(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/enterprise/getDetails',
    method: 'get',
    params,
  });
}

/**
 * 入园企业 - 新增
 * @param params
 */
export function updateParkEnterprise(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/enterprise/editEnterprise',
    method: 'post',
    data: params,
  });
}
